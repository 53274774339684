var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full bg-img vx-COL no-gutter items-center justify-center"},[_c('div',{staticClass:"vx-row"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.BiadjoStatus == 'BS4' ||
          _vm.BiadjoStatus == 'BS5' ||
          _vm.BiadjoStatus == 'BS6' ||
          _vm.BiadjoStatus == 'BS7' ||
          _vm.BiadjoStatus == 'BS8' ||
          _vm.BiadjoStatus == 'BS9' ||
          _vm.BiadjoStatus == 'BS10'
      ),expression:"\n        BiadjoStatus == 'BS4' ||\n          BiadjoStatus == 'BS5' ||\n          BiadjoStatus == 'BS6' ||\n          BiadjoStatus == 'BS7' ||\n          BiadjoStatus == 'BS8' ||\n          BiadjoStatus == 'BS9' ||\n          BiadjoStatus == 'BS10'\n      "}],staticClass:"text-center",staticStyle:{"margin":"auto"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("YourCancelationFeesIs"))+" : "+_vm._s(_vm._f("numFormat")(_vm.BiadjoCancelationFee,"###,##0.00"))+" "+_vm._s(_vm.$t(_vm.BiadjoCountryCode + "_Currency"))+" ")])])]),_c('div',{staticClass:"vx-row",staticStyle:{"padding-top":"20px"}},[_c('vs-button',{staticStyle:{"margin":"auto"},attrs:{"icon-pack":"feather","icon":"icon-close"},on:{"click":_vm.cancelBiadjo}},[_vm._v(_vm._s(_vm.$t("CancelBiadjo")))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }